@import './src/scss/variables';
@import './src/scss/app';
@import './src/scss/moment-picker';

.widget__input {
	max-width: fit-content;
	flex-wrap: wrap;
  overflow: auto;
}

.widget__flex_row {
  .widget__input-group {
    .widget__input-number {
      width: 80px !important;
    }
  }
}

.widget__input_full {
	max-width: fit-content;

  .widget__input-group {
    max-width: 100%;
    width: 100% !important;

    .widget__input-number {
      max-width: 100%;
      width: 100%;
    }
  }
}

.widget__result-text:first-letter {
	text-transform: uppercase;
}